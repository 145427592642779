<template>
  <div class="maxHeight">
    <div class="header flex">
      <div class="title flex">
        <img src="../../assets/img/daily.png" alt="" width="28">
        <h2>屏障环境清洁消毒记录表</h2>
      </div>
    </div>
    <el-tabs v-model="activeName" class="contentBody">
      <el-tab-pane label="日历" name="first">
        <div class="noRecord flex" v-if="Object.keys(oneDayList).length==0">
          <span class="selectTime">{{ dateV | formatDay1 }}</span>
          <span style="width: 100px;">暂无记录</span>
        </div>
        <div class="calendarRecord" v-else v-for="monitor in oneDayList"
             :key="monitor.id">
          <span class="selectTime">{{ dateV | formatDay1 }}</span>
          <p><span>房间：</span><span>{{ clean.roomName }}</span></p>
          <p><span>所用消毒液：</span><span>{{ clean.fluids }}</span></p>
          <p><span>操作：</span><span>{{ clean.handleType == 1 ? '常规清洁消毒' : '每周大清洁' }}</span></p>
          <p><span>操作人：</span><span>{{ clean.createBy }}</span></p>
        </div>
        <el-calendar v-model="dateV" class="calendar">
          <template
              slot="dateCell" slot-scope="{date,data}">
            <span class="flex" :class=" (new Date().getTime()-new Date(data.day).getTime())> 0 ? 'pastTime':''">
              <span class="flex"
                    :class="!commonList.includes(data.day)&& (new Date().getTime()-new Date(data.day).getTime())> 0?'noDa':''">
                {{ data.day.split('-')[2] }}
              </span>
            </span>
          </template>
        </el-calendar>
      </el-tab-pane>
      <el-tab-pane label="列表" name="second">
        <div class="flex">
          <el-date-picker
              v-model="selectTime" size="small"
              type="daterange"
              class="timeRange"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="search()">
          </el-date-picker>
          <el-input type="search" clearable class="keyRecord" size="small" placeholder="请输入操作人"
                    v-model="key"></el-input>
          <el-button type="primary" @click="search" size="small" class="search">搜索</el-button>
        </div>
        <el-table
            :data="tableData"
            max-height="calc(100vh - 413px)" height="calc(100vh - 413px)"
            :header-cell-style="{'background':'#EAF3FF','color': '#09BEE9',}"
            class="record-table">
          <el-table-column
              label="记录日期"
              align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.createDate">{{ scope.row.createDate | formatDay }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="更新日期"
              align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.updateDate">{{ scope.row.updateDate | formatDay }}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="fluids"
              label="所用消毒液(每7天更换一次)"
              align="center"
              show-overflow-tooltip>
          </el-table-column>
          <el-table-column
              align="center"
              width="180"
              label="操作">
            <template slot-scope="scope">
              <span v-if="scope.row.handleType==2">每周大清洁</span>
              <span v-else>常规清洁消毒</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="createBy"
              label="操作人"
              align="center">
          </el-table-column>
        </el-table>
        <div class="flex pagination ">
          <el-pagination
              prev-text="<"
              next-text=">"
              @current-change="handleCurrentChange"
              :current-page="page.current"
              :page-size="page.size"
              layout="prev, pager, next"
              :total="page.total">
          </el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>

</template>

<script>
import moment from 'moment'

moment.locale('zh-cn')
export default {
  name: "cleanEnvironment",
  data() {
    return {
      activeName: 'first',
      dateV: new Date(),
      oneDayList: [],
      commonList: [],
      selectTime: '',
      key: '',
      tableData: [],
      page: {
        total: 1,
        pageSize: 6,
        pageNum: 1,
      },
    }
  },
  watch: {
    dateV: { // 监视搜索字段
      handler() {
        this.getDataFromServer();
      },
      deep: true
    },
  },
  mounted() {
    this.listRecordFromService();
    this.getDataFromServer();
  },
  methods: {
    listRecordFromService() {
      this.$get('/daily/ac-daily-clean', {
        selectTime: this.selectTime,
        key: this.key,
        page: this.page.pageNum,
        size: this.page.pageSize
      }).then(res => {
        this.tableData = res.data.rows;
        this.page.total = res.data.total;
      })
    },
    getDataFromServer() {
      let startDate = new Date(this.dateV).getTime() - 8 * 3600 * 1000;
      // 发起请求
      this.$get("/daily/ac-daily-clean/oneDay", {
        startDate: startDate, // 搜索条件
        //roomId: -1
      }).then(resp => { // 这里使用箭头函数
        this.oneDayList = resp.data.oneDayList;
        this.commonList = resp.data.commonList;
        if (this.commonList != null) {
          for (let i = 0; i < this.commonList.length; i++) {
            this.commonList[i] = moment(this.commonList[i]).format("YYYY-MM-DD");
          }
        }
      })
    },
    search() {
      this.listRecordFromService();
    },
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.listRecordFromService();
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/css/daily";
</style>
